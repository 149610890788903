import React from 'react';
import SEO from '../components/seo';

import Layout from '../components/layout';
import HeroSlider from '../components/index/HeroSlider';
import CTAImages from '../components/index/CTAImages';
import Citat from '../components/index/Quote';
import { validateToken } from "../services/auth"
// import ReactPlayer from "react-player"
// import SueJ from '../images/video/SueJ.mp4'

const IndexPage = () => {
  validateToken()
  return (
  <Layout>
    <SEO title="Home" keywords={['secta', 'tourism', 'cornwall']} />
    <HeroSlider />
    <CTAImages />
    {/*<ReactPlayer url='https://drive.google.com/file/d/1pbNkeFpr5fNY-QMh8F7JrIiGdAHrIOh_/view' />*/}
    {/*<ReactPlayer url='https://www.youtube.com/watch?v=vaFw-VXQVrg' />*/}
    <Citat />
  </Layout>
)};

export default IndexPage;
