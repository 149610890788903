import React from "react"

import CTA from "./CTA"
import Service from "../../images/secta-services.jpg"
import Events from "../../images/secta-events.jpg"
import News from "../../images/secta-breaking-news.jpg"

const CTAImages = () => {

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <CTA
              image={Service}
              link="/services"
              text="Services"
            />
          </div>
          <div className="col-md-4">
            <CTA
              image={Events}
              link="/events"
              text="Events"
            />
          </div>
          <div className="col-md-4">
            <CTA
              image={News}
              link="/news"
              text="News"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default CTAImages
