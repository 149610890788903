import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import citatimg from "../../images/tango_citat.svg"
import { CitatWrapper, CitatImg } from "./styles/CitatStyles"

const Citat = () => {
  const data = useStaticQuery(graphql`
    query {
      wordpressPage(slug: {eq: "home"}) {
        acf {
          quote_1
          quote_author
        }
      }
    }
  `)

  return (
    <CitatWrapper>
      <div className="container">
        <div className="row">
          <div className="col">
            <CitatImg src={citatimg} alt="quote" />
            <h6>{data.wordpressPage.acf.quote_1}</h6>
            {data.wordpressPage.acf.quote_author}
          </div>
        </div>
      </div>
    </CitatWrapper>
  )
}

export default Citat
