import React from 'react';
import { Link } from 'gatsby';

import { StyledImg, MsSlide } from './styles/HeroSliderStyles';

const Slide = ({ slide, active }) => (
  <MsSlide className={active ? 'active' : ''}>
    <StyledImg fluid={slide.acf.featured_media.localFile.childImageSharp.fluid} />
    <div className="wbn-overlay-text">
      {/*<h1 className="wbn-header">{slide.acf.header}</h1>*/}
      <h1 className="wbn-header">Welcome</h1>
      <h3 className="wbn-header">to</h3>
      <h1 className="wbn-header">SECTA</h1>
      {/*<p className="wbn-text">{slide.acf.slider_text}</p>*/}
      <p className="wbn-text">Home of the South East Cornwall Tourism Association</p>
      <Link to={slide.acf.slider_button_link}>
        <button type="button">{slide.acf.slider_button_text}</button>
      </Link>
    </div>
  </MsSlide>
);

export default Slide;
