import styled from 'styled-components';

export const CitatWrapper = styled.div`
  background: #4d4d4d;
  text-align: center;
  padding: 40px 0;
  color: #fff;
`;
export const CitatImg = styled.img` 
  //display: block;
  max-width: 40px !important;
  margin: 20px auto 40px auto;
`;
